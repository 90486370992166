import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Services (under construction)`}</h2>
    <ProjectCard title="Freiheit" link="https://www.bristoljon.uk" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Mobile Apps
    </ProjectCard>
    <ProjectCard title="Web Sites" link="https://www.behance.net/gallery/52915793/Harry-Potter" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Static Web Sites (like this)
    </ProjectCard>
    <ProjectCard title="Web Apps" link="https://www.behance.net/gallery/43907099/Tomb-Raider" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Web Apps
    </ProjectCard>
    <ProjectCard title="Data" link="https://www.behance.net/gallery/38068151/Eagle" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Data Visualisation
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      